* {
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f5;
}

.App {
  width: 100%;
  max-width: 500px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.setup-container,
.game-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

h2,
h3,
h4 {
  color: #333;
}

.input-field {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 1rem;
  width: 100%;
}

.start-button,
.game-button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.start-button:hover,
.game-button:hover {
  background-color: #0056b3;
}

.game-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .App {
    padding: 15px;
    font-size: 0.9rem;
  }

  .input-field,
  .start-button,
  .game-button {
    font-size: 0.9rem;
  }
}